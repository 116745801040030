


























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChartRes, AnalyGraph } from '@/types/analy'
import gaugeChart from '@/components/chart/gaugeChart.vue'
import variables from '@/styles/_variables.scss'
import lineChart from '@/components/chart/lineChart.vue'
import echarts from 'echarts'

@Component({
  name: 'tableBar',
  components: {
    lineChart,
    gaugeChart
  }
})
export default class extends Vue {
  @Prop({ required: true }) data!: ChartRes
  @Prop({ required: false, default: 'Col' }) tableType!: string
  @Prop({ required: false, default: 'pie' }) chartType!: string
  @Prop({ required: true }) keyName!: string
  loading = true
  bomContent = this.data.context
  @Watch('data', { immediate: true, deep: true })
  getPropData(v: ChartRes) {
    if (v.id) {
      this.loading = false
      // console.log('getPropData  v', v)
    }
  }
}
Vue.component('coms', {
  props: {
    ctx: {
      type: String,
      default: ''
    }
  },
  render(h) {
    const com = Vue.extend({
      template: `<div>${this.ctx === null ? '' : this.ctx}</div>`
    })
    return h(com)
  }
})
