import request from '@/utils/request'

// 投资风险测评点击下一步
export const getFinancialAnalysisNext = (params: any) =>
  request({
    url: '/blackcat/customer/kyc/save',
    method: 'POST',
    data: params
  })
  // 投资风险测评页面获取信息
export const getFinancialAnalysisInfo = (params: any) =>
  request({
    url: '/blackcat/customer/kyc/find',
    method: 'get',
    params
  })
  // 根据学历得到学费
export const getMoneyEdu = (params: any) =>
  request({
    url: '/blackcat/allocation/edu',
    method: 'get',
    params
  })
